import React from 'react'

import { Helmet } from 'react-helmet'

import './home-page.css'

const HomePage = (props) => {
  return (
    <div className="home-page-container">
      <Helmet>
        <title>exported project</title>
      </Helmet>
      <div className="home-page-home-page">
        <div className="home-page-navbar1">
          <div className="home-page-container1">
            <div className="home-page-column">
              <svg viewBox="0 0 1024 1024" className="home-page-icon">
                <path d="M640 384v-86h-256v428h256v-86h-170v-86h170v-84h-170v-86h170zM810 128q34 0 60 26t26 60v596q0 34-26 60t-60 26h-596q-34 0-60-26t-26-60v-596q0-34 26-60t60-26h596z"></path>
              </svg>
              <span className="home-page-text aH1">
                <span>Extensionery</span>
                <br></br>
              </span>
            </div>
          </div>
        </div>
        <div className="home-page-header20">
          <div className="home-page-column1">
            <div className="home-page-content">
              <span className="home-page-text03">
                Medium length hero headline goes here and Describe Product.
              </span>
              <span className="home-page-text04">
                <span className="home-page-text05 aText">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Suspendisse varius enim in eros elementum tristique.
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <br></br>
              </span>
            </div>
            <div className="home-page-actions">
              <div className="home-page-form">
                <input
                  type="text"
                  placeholder="Enter your email"
                  className="home-page-input"
                />
                <button className="home-page-button">
                  <span className="home-page-text07">Sign up</span>
                </button>
              </div>
            </div>
            <span className="home-page-text08">
              <span className="home-page-text09 aText">
                By signing up you confirming that you agree with our
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span className="home-page-text10 aText">T&amp;C</span>
              <br></br>
              <br></br>
            </span>
          </div>
        </div>
        <div className="home-page-testimonial4">
          <div className="home-page-content01">
            <img
              alt="StarsI2402"
              src="/playground_assets/starsi2402-oqs5.svg"
              className="home-page-stars"
            />
            <span className="home-page-text13">
              <span className="home-page-text14">
                Probably, the
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span className="home-page-text15">only newsletter</span>
              <span className="home-page-text16">
                {' '}
                I eagerly wait for every
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span className="home-page-text17">saturday!</span>
            </span>
            <div className="home-page-avatar">
              <img
                alt="AvatarImageI2402"
                src="/playground_assets/avatarimagei2402-2ag-200w.png"
                className="home-page-avatar-image"
              />
              <div className="home-page-avatar-content">
                <span className="home-page-text18">Fardeen Khan</span>
                <span className="home-page-text19 aText">Web Developer</span>
              </div>
              <img
                alt="DividerI2402"
                src="/playground_assets/divideri2402-hrca.svg"
                className="home-page-divider"
              />
              <img
                alt="WebflowBlackI2402"
                src="/playground_assets/webflowblacki2402-4tgp.svg"
                className="home-page-webflow-black"
              />
            </div>
          </div>
        </div>
        <div className="home-page-layout134">
          <div className="home-page-content02">
            <div className="home-page-section-title">
              <span className="home-page-text20 otherTagline">
                <span>Tagline</span>
              </span>
              <div className="home-page-content03">
                <span className="home-page-text22">
                  <span>Medium length section heading goes here</span>
                </span>
                <span className="home-page-text24">
                  <span>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Suspendisse varius enim in eros elementum tristique. Duis
                    cursus, mi quis viverra ornare, eros dolor interdum nulla,
                    ut commodo diam libero vitae erat.
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="home-page-f-a-q9">
          <div className="home-page-section-title1">
            <span className="home-page-text26 h2">
              <span>FAQs</span>
            </span>
            <span className="home-page-text28 aText">
              <span>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Suspendisse varius enim in eros elementum tristique.
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
            </span>
          </div>
          <div className="home-page-content04">
            <div className="home-page-row">
              <img
                alt="DividerI2402"
                src="/playground_assets/divideri2402-yq6j-200h.png"
                className="home-page-divider1"
              />
              <div className="home-page-content05">
                <span className="home-page-text30">
                  Question text goes here?
                </span>
                <span className="home-page-text31 aText">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Suspendisse varius enim in eros elementum tristique. Duis
                  cursus, mi quis viverra ornare, eros dolor interdum nulla, ut
                  commodo diam libero vitae erat. Aenean faucibus nibh et justo
                  cursus id rutrum lorem imperdiet. Nunc ut sem vitae risus
                  tristique posuere.
                </span>
              </div>
            </div>
            <div className="home-page-row1">
              <img
                alt="DividerI2402"
                src="/playground_assets/divideri2402-vqwr-200h.png"
                className="home-page-divider2"
              />
              <div className="home-page-content06">
                <span className="home-page-text32">
                  <span className="home-page-text33">
                    Question text goes here?
                  </span>
                  <br></br>
                </span>
                <span className="home-page-text35 aText">
                  <span>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Suspendisse varius enim in eros elementum tristique. Duis
                    cursus, mi quis viverra ornare, eros dolor interdum nulla,
                    ut commodo diam libero vitae erat. Aenean faucibus nibh et
                    justo cursus id rutrum lorem imperdiet. Nunc ut sem vitae
                    risus tristique posuere.
                  </span>
                </span>
              </div>
            </div>
            <div className="home-page-row2">
              <img
                alt="DividerI2402"
                src="/playground_assets/divideri2402-3h09-200h.png"
                className="home-page-divider3"
              />
              <div className="home-page-content07">
                <span className="home-page-text37">
                  Question text goes here?
                </span>
                <span className="home-page-text38 aText">
                  <span>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Suspendisse varius enim in eros elementum tristique. Duis
                    cursus, mi quis viverra ornare, eros dolor interdum nulla,
                    ut commodo diam libero vitae erat. Aenean faucibus nibh et
                    justo cursus id rutrum lorem imperdiet. Nunc ut sem vitae
                    risus tristique posuere.
                  </span>
                </span>
              </div>
            </div>
            <div className="home-page-row3">
              <img
                alt="DividerI2402"
                src="/playground_assets/divideri2402-2bx8-200h.png"
                className="home-page-divider4"
              />
              <div className="home-page-content08">
                <span className="home-page-text40">
                  Question text goes here?
                </span>
                <span className="home-page-text41 aText">
                  <span>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Suspendisse varius enim in eros elementum tristique. Duis
                    cursus, mi quis viverra ornare, eros dolor interdum nulla,
                    ut commodo diam libero vitae erat. Aenean faucibus nibh et
                    justo cursus id rutrum lorem imperdiet. Nunc ut sem vitae
                    risus tristique posuere.
                  </span>
                </span>
              </div>
            </div>
          </div>
          <div className="home-page-content09">
            <div className="home-page-content10">
              <span className="home-page-text43 h2">
                <span>Still have a question?</span>
              </span>
              <span className="home-page-text45">
                <span>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                </span>
              </span>
            </div>
            <button className="home-page-button1">
              <span className="home-page-text47 textRegularNormal">
                <span>Contact</span>
              </span>
            </button>
          </div>
        </div>
        <footer className="home-page-footer">
          <div className="home-page-container2">
            <img
              alt="logo"
              src="https://presentation-website-assets.teleporthq.io/logos/logo.png"
              className="home-page-image"
            />
            <nav className="home-page-nav">
              <span className="home-page-text49">About</span>
              <span className="home-page-text50">Features</span>
              <span className="home-page-text51">Pricing</span>
              <span className="home-page-text52">Team</span>
              <span className="home-page-text53">Blog</span>
            </nav>
          </div>
          <div className="home-page-separator"></div>
          <div className="home-page-container3">
            <span className="home-page-text54">
              © 2021 teleportHQ, All Rights Reserved.
            </span>
            <div className="home-page-icon-group">
              <svg
                viewBox="0 0 950.8571428571428 1024"
                className="home-page-icon2"
              >
                <path d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"></path>
              </svg>
              <svg
                viewBox="0 0 877.7142857142857 1024"
                className="home-page-icon4"
              >
                <path d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"></path>
              </svg>
              <svg
                viewBox="0 0 602.2582857142856 1024"
                className="home-page-icon6"
              >
                <path d="M548 6.857v150.857h-89.714c-70.286 0-83.429 33.714-83.429 82.286v108h167.429l-22.286 169.143h-145.143v433.714h-174.857v-433.714h-145.714v-169.143h145.714v-124.571c0-144.571 88.571-223.429 217.714-223.429 61.714 0 114.857 4.571 130.286 6.857z"></path>
              </svg>
            </div>
          </div>
        </footer>
      </div>
    </div>
  )
}

export default HomePage
